import { defineStore } from "pinia";
import { useMainStore } from '@/store/main';
import { API_URL } from "@/utils/urls";
import { FoodType } from "@/types/food";

const MAIN_STORE = useMainStore()


export const useRegistrationStore = defineStore("registrationStore", {
  state: () => ({
    foods: null as FoodType[] | null,
  }),
  actions: {
    async getSiloProfile(pk: any): Promise<void> {
      const siloProfile = await MAIN_STORE.getFromAPI(`${API_URL}/silo_profiles/${pk}`)
      return siloProfile
    },
    async getSiloAcquisition(pk: any): Promise<void> {
      const siloProfile = await MAIN_STORE.getFromAPI(`${API_URL}/smartfeed_v0_acquisitions/${pk}`)
      return siloProfile
    },
    async fetchFoods(): Promise<void> {
      let data = await MAIN_STORE.getFromAPI(`${API_URL}/foods/`);
      this.foods = data.results
      while (data?.next != null) {
        data = await MAIN_STORE.getFromAPI(data.next);
        if (this.foods == null) return;
        this.foods.concat(data.results)
      }
    },
    async registerUser(data: any): Promise<void> {
      const user_posted = await MAIN_STORE.postOnAPI(`${API_URL}/users/create_user/`, data)
    
      const employee = {
        user: user_posted.pk,
        phone_number: "",
        company: parseInt(data.company),
        role: data.role,
        associated_farm: data.associated_farm === "null" ? null : parseInt(data.associated_farm)
      }
      await MAIN_STORE.postOnAPI(`${API_URL}/employees/`, employee)
    },
    async registerCompany(data: any): Promise<void> {
      const company = {name: data.name, farms: [], foods: []}
      const company_posted = await MAIN_STORE.postOnAPI(`${API_URL}/companies/`, company)

      const reference_weight_curve = {
        name: `Curva referência ${company_posted.name} (fake)`,
        company: company_posted.pk,
        curve: {},
      }
      await MAIN_STORE.postOnAPI(`${API_URL}/reference_weight_curves/`, reference_weight_curve)
    },
    async registerSilo(silo_data: any): Promise<void> {
      await MAIN_STORE.postOnAPI(`${API_URL}/silos/`, silo_data)
    },
  },
  getters: {
    getFoods: state => state.foods,
  },
});
